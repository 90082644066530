import React, { useEffect, useState, ReactElement } from "react"
import {
  withStreamlitConnection,
  Streamlit,
  ComponentProps,
} from "streamlit-component-lib";
import { Container, Navbar, NavDropdown } from 'react-bootstrap';
// import './Billing.css';
// import { info } from "console";


function Login({ args }: ComponentProps): ReactElement {
  const { logout, email_py, csp_py, do_billing, hide_auth, billed_done, invoices, redirect_base_uri, billing_success, billing_canceled, stripe_session_id } = args

  let [showingBiling, setShowingBiling] = useState(false);
  let [email, setEmail] = useState('');
  let [csp, setCsp] = useState('');
  let [showLogin, setShowLogin] = useState(false);
  let [doBillingInit, setDoBillingInit] = useState(false);

  const doLogOut = () => {
    sessionStorage.clear();
    Streamlit.setComponentValue({ type: "logout" })
  };

  const google = () => {
    Streamlit.setComponentValue({ type: "google" })
  };

  const github = () => {
    Streamlit.setComponentValue({ type: "github" })
  };

  // eslint-disable-next-line
  const facebook = () => {
    Streamlit.setComponentValue({ type: "face" });
  };

  const azure = () => {
    Streamlit.setComponentValue({ type: "azure" })
  };

  const showBilling = () => {
    setShowingBiling(true);
  };

  const hideBilling = () => {
    setShowingBiling(false);
  };

  const createCheckout = async () => {
    console.log("send fo billing...");
    Streamlit.setComponentValue({ doBilling: true })
  };

  const cancelSubscription = async () => {
    Streamlit.setComponentValue({ cancelBilling: true })
  };

  const goHome = () => {
    Streamlit.setComponentValue({ goHome: true })
  };

  const doBillingAtLogin = () => {
    setDoBillingInit(true);
    setShowLogin(true);
  };

  const goBack = () => {
    setDoBillingInit(false);
    setShowLogin(false);
  };

  const Logo = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="21px"
      height="24px"
      viewBox="0 0 21 24"
      version="1.1"
    >
      <defs />
      <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="0-Default"
          transform="translate(-121.000000, -40.000000)"
          fill="#E184DF"
        >
          <path
            d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
            id="Pilcrow"
          />
        </g>
      </g>
    </svg>
  );

  const ProductDisplay = () => (
    <section>
      <div className="product">
        <Logo />
        <div className="description">
          <h3>Subscription</h3>
          <h5>$29.99 / month</h5>
        </div>
      </div>
      <div>
        {/* Add a hidden field with the lookup_key of your Price */}
        <input type="hidden" name="lookup_key" value="finopsSub" />
        <button className="billing-btn" id="checkout-and-portal-button" onClick={createCheckout} >
          Checkout
        </button>
      </div>
    </section>
  );

  const MainNavBar = () => (
    <nav id="navbar-main" className="navbar navbar-main navbar-dark navbar-theme-primary headroom py-lg-2 px-lg-6">
      <div className="" id="navbar_global">
        <ul className="navbar-nav navbar-nav-hover justify-content-right">
          <li className="nav-item" onClick={goHome}>
            <div className="row">
              <span className="text-light pull-right">
                User: {email}&nbsp;&nbsp;
              </span>


              <span className="pull-right text-light">
                Current plan: {billed_done ? "(Standard)" : "(Free)"}&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </li>
        </ul>
      </div>

      <div className="row">
        <button className="btn btn-outline-white my-2 my-sm-0" onClick={doLogOut}>Logout</button>
      </div>
      <div className="d-flex d-lg-none align-items-center ml-auto">
        <button className="navbar-main" type="button" data-toggle="collapse" data-target="#navbar_global"
          aria-controls="navbar_global" aria-expanded="false" aria-label=""></button>
      </div>

    </nav>
  );

  const InvoiceTable = () => (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Amount</th>
          <th scope="col">Currency</th>
          <th scope="col">PDF</th>
        </tr>
      </thead>
      <tbody>
        {invoices != null && invoices !== undefined && invoices.map((invoice: any, index: any) => {
          return (
            <tr>
              <th scope="row">{index}</th>
              <td>{invoice.amount_paid / 100}</td>
              <td>{invoice.currency}</td>
              <td><a href={invoice.invoice_pdf}>Download PDF</a></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  );

  interface BillingProps {
    login_billing?: boolean;
  }
  function BillingComponent(props: BillingProps) {
    return (
      <div className="container-fluid">
        <div className="row pt-4">
          {billed_done ? (<div className="col-sm-12">
            <section className="pb-7 pb-lg-11">
              <div className="row pb-4">
                <div className="col-12 pb-2">
                  <h4>Invoice History</h4>
                </div>
                <div className="col-12">
                  <InvoiceTable />
                </div>
              </div>
              <div className="row text-gray ">
                <div className="col-12 pb-2">
                  <h4>Change your Plan</h4>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100 card-container shadow-soft border-light mb-5 mb-lg-6">
                    <div className="card-header border-light py-5 px-4">
                      <div className="d-flex mb-3">
                        <span className="h5 mb-0">$</span>
                        <span className="price display-2 text-secondary mb-0" data-annual="299" data-monthly="199">$$</span>
                        <span className="h6 font-weight-normal align-self-end">/month </span>
                      </div>
                      <h4 className="mb-3 text-black">Enterprise</h4>
                      <p className="font-weight-normal mb-0">The ideal plan for enterprise.</p>
                    </div>
                    <div className="card-body pt-5">
                      <ul className="list-group simple-list">
                        <li className="list-group-item font-weight-normal"> All of the standard <span className="font-weight-bolder"> +</span></li>
                        <li className="list-group-item font-weight-normal"> Custom corpus <span className="font-weight-bolder"></span> plan</li>
                        <li className="list-group-item font-weight-normal"> Recommender system</li>
                        <li className="list-group-item font-weight-normal"> Elastic Integration</li>
                        <li className="list-group-item font-weight-normal"> Kibana Deshboards</li>
                        <li className="list-group-item font-weight-normal"> Premium support</li>
                      </ul>
                    </div>
                    <div className="card-footer px-4 pb-4">
                      <a className="btn btn-block btn-outline-secondary" href="mailto:info@mlsoft.ai?Subject=W6H%20Premium%20plan%20info%20request">
                        Contact us<span className="icon icon-xs ml-3"><i className="fas fa-arrow-right"></i></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 offset-lg-4">
                  <div className="card h-100 card-container shadow-soft border-light mb-5 mb-lg-6">
                    <div className="card-header border-light py-5 px-4">
                      <div className="d-flex mb-3">
                        <span className="h5 mb-0">$</span>
                        <span className="price display-2 mb-0" data-annual="0" data-monthly="0">0</span>
                        <span className="h6 font-weight-normal align-self-end">/month</span>
                      </div>
                      <h5 className="mb-3 text-black">Cancel Subscription</h5>
                      <p className="font-weight-normal mb-0">The cancelation will start at the end of the current billing period.</p>
                      <p className="font-weight-normal mb-0">Access to the following features will be lost:</p>
                    </div>
                    <div className="card-body pt-5">
                      <ul className="list-group simple-list">
                        <li className="list-group-item font-weight-normal"> All of the Paid + </li>
                        <li className="list-group-item font-weight-normal"> Recomender systems based requirements </li>
                        <li className="list-group-item font-weight-normal"> Elastic integration</li>
                        <li className="list-group-item font-weight-normal"> Suport</li>
                      </ul>
                    </div>
                    <div className="card-footer px-4 pb-4">
                      <button className="btn btn-block btn-outline-danger" onClick={cancelSubscription}>
                        Cancel Subscription<span className="icon icon-xs ml-3"><i className="fas fa-arrow-right"></i></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>) : (<div className="col-sm-12">
            {/* <ProductDisplay /> */}
            <section className="pb-7 pb-lg-11">
              <div className="row text-gray ">
                <div className="col-12 col-lg-4">
                  <div className="card h-100 card-container shadow-soft border-light mb-5 mb-lg-6">
                    <div className="card-header border-light py-5 px-4">
                      <div className="d-flex mb-3">
                        <span className="h5 mb-0">$</span>
                        <span className="price display-2 mb-0" data-annual="0" data-monthly="0">0</span>
                        <span className="h6 font-weight-normal align-self-end">/month </span>
                      </div>
                      <h4 className="mb-3 text-black">Free.</h4>
                      <p className="font-weight-normal mb-0">Generate System Design and User Requirements</p>
                      <p className="font-weight-normal mb-0"></p>
                      <p className="font-weight-normal mb-0"></p>
                      <p className="font-weight-normal mb-0"></p>
                    </div>
                    <div className="card-body pt-5">
                      <ul className="list-group simple-list">
                        <li className="list-group-item font-weight-normal"> System Design for one Project<span className="font-weight-bolder"> </span></li>
                        <li className="list-group-item font-weight-normal"> Generate EA for one Organization</li>
                        <li className="list-group-item font-weight-normal"> Can not edit diagrams</li>
                        <li className="list-group-item font-weight-normal"> Limited to 50,000 tokens </li>
                      </ul>
                    </div>
                    <div className="card-footer px-4 pb-4">
                      <button className="btn btn-block btn-outline-gray animate-up-2" onClick={() => { props?.login_billing ? setShowLogin(true) : goHome() }}>
                        Start for Free <span className="icon icon-xs ml-3"><i className="fas fa-arrow-right"></i></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100 card-container shadow-soft border-light mb-5 mb-lg-6">
                    <div className="card-header border-light py-5 px-4">
                      <div className="d-flex mb-3 text-primary">
                        <span className="h5 mb-0">$</span>
                        <span className="price display-2 text-primary mb-0" data-annual="99" data-monthly="29.99">29.99</span>
                        <span className="h6 font-weight-normal align-self-end">/month </span>
                      </div>
                      <h4 className="mb-3 text-black">Standard</h4>
                      <p className="font-weight-normal mb-0">Generate System Design and User Requirements (Editable Diagrams)</p>
                    </div>
                    <div className="card-body pt-5">
                      <ul className="list-group simple-list">
                        <li className="list-group-item font-weight-normal"> Generate System Design for multiple Projects</li>
                        <li className="list-group-item font-weight-normal"> Generate EA for multiple Organizations </li>
                        <li className="list-group-item font-weight-normal"> Modify System Diagrams</li>
                        <li className="list-group-item font-weight-normal"> Unlimited Tokens</li>
                      </ul>
                    </div>
                    <div className="card-footer px-4 pb-4">
                      <button className="btn btn-block btn-outline-primary" onClick={() => { props?.login_billing ? doBillingAtLogin() : createCheckout() }}>
                        Go to Standard<span className="icon icon-xs ml-3"><i className="fas fa-arrow-right"></i></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100 card-container shadow-soft border-light mb-5 mb-lg-6">
                    <div className="card-header border-light py-5 px-4">
                      <div className="d-flex mb-3">
                        <span className="h5 mb-0">$</span>
                        <span className="price display-2 text-secondary mb-0" data-annual="299" data-monthly="199">$$</span>
                        <span className="h6 font-weight-normal align-self-end">/month</span>
                      </div>
                      <h4 className="mb-3 text-black">Enterprise</h4>
                      <p className="font-weight-normal mb-0">Enterprise plan with Custom Corpus and ElasticSearch</p>
                    </div>
                    <div className="card-body pt-5">
                      <ul className="list-group simple-list">
                        <li className="list-group-item font-weight-normal"> All of the standard <span className="font-weight-bolder"> +</span></li>
                        <li className="list-group-item font-weight-normal"> Trained on Custom Corpus <span className="font-weight-bolder"></span></li>
                        <li className="list-group-item font-weight-normal"> Integration with corporate systems </li>
                        <li className="list-group-item font-weight-normal"> Elastic and Kibana included</li>
                        <li className="list-group-item font-weight-normal"> Premimum Support </li>
                      </ul>
                    </div>
                    <div className="card-footer px-4 pb-4">
                      <a className="btn btn-block btn-outline-secondary" href="mailto:info@mlsoft.ai?Subject=W6H%20Premium%20plan%20info%20request">
                        Contact us<span className="icon icon-xs ml-3"><i className="fas fa-arrow-right"></i></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>)}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (showingBiling) {
      Streamlit.setFrameHeight(2400)
    }
    else if (!logout) {
      Streamlit.setFrameHeight(2840)
    } else {
      Streamlit.setFrameHeight(100)
    }

  })

  useEffect(() => {
    if (do_billing) {
      showBilling()
    } else {
      hideBilling()
    }
  }, [do_billing])

  useEffect(() => {
    console.log("sesion effect")
    if (email_py && email_py !== "") {
      sessionStorage.setItem("email", email_py);
      sessionStorage.setItem("csp", csp_py);
      setEmail(email_py);
      setCsp(csp_py);
      console.log("what has logout var?: ", logout)
      if (logout) {
        let sessionDobilling = (sessionStorage.getItem("doBillingInit") ?? "false") === "true";
        console.log("sesion effect do bill after login?: ", sessionDobilling)
        if (sessionDobilling) {
          setDoBillingInit(false);
          console.log("do billing after account creation.");
          Streamlit.setComponentValue({ doBilling: true })
        }
      }
    } else {
      let sessionEmail = sessionStorage.getItem("email") ?? "";
      let sessionCsp = sessionStorage.getItem("csp") ?? "";

      if (sessionEmail && sessionEmail !== "") {
        console.log("sesion effect login from session")
        setEmail(sessionEmail);
        setCsp(sessionCsp);
        Streamlit.setComponentValue({ type: "session", email: sessionEmail, csp: sessionCsp })
      }
    }
  }, [email_py, csp_py, logout])

  useEffect(() => {
    sessionStorage.setItem("doBillingInit", doBillingInit ? "true" : "false");
  }, [doBillingInit])

  return (
    <div>

      {!hide_auth && (
        <div className="container-fluid">
          {!logout && (<div>
            <div className="row">
              <div className="offset-3 col-6">
                <img src="./img/Logo.png" className="login-logo mb-4" alt="logo" />
              </div>
              <div className=" col-3 text-right">
                {showLogin && (<button type="button" className="btn btn-link" onClick={goBack}>Pricing</button>)}
                {!showLogin && (<button type="button" className="btn btn-link" onClick={() => { setShowLogin(true) }}>Already have an account?</button>)}
              </div>
            </div>
            <hr className="my-4" />
          </div>)}

          {!logout ? (<div className="row">
            <div className="col-md-4 col-sm-1 text-right">
            </div>
            <div className="col-md-4 col-sm-10">
              {showLogin && (<React.Fragment>
                <button className="btn btn-lg w-100 btn-primary mb-2 google" onClick={google} type="submit">
                  <div className="row">
                    <div className="col-2 login-text-right">
                      <img src="./img/google.png" alt="" className="icon" />
                    </div>
                    <div className="col-10 login-text-left">
                      Sign in with Google
                    </div>
                  </div>
                </button>
                <button className="btn btn-lg w-100 btn-primary mb-2 github" onClick={github} type="submit">
                  <div className="row">
                    <div className="col-2 login-text-right">
                      <img src="./img/github.png" alt="" className="icon" />
                    </div>
                    <div className="col-10 login-text-left">
                      Sign in with Github
                    </div>
                  </div>
                </button>
                <button className="btn btn-lg w-100 btn-primary mb-2 azure-ad" onClick={azure} type="submit">
                  <div className="row">
                    <div className="col-2 login-text-right">
                      <img src="./img/ms-login.png" alt="" className="icon" />
                    </div>
                    <div className="col-10 login-text-left">
                      Sign in with Azure AD
                    </div>
                  </div>
                </button>
              </React.Fragment>)}
            </div>
            <div className="col-md-4 col-sm-1">
            </div>
            <div className="col-md-12 col-sm-12">
            </div>
            <div className="col-md-2 col-sm-1">
            </div>
            <div className="col-md-8 col-sm-1">
              {!showLogin && (<BillingComponent login_billing={true} />)}
            </div>

          </div>) : (
            <MainNavBar />
          )}
        </div>
      )}
      {showingBiling && (
        <BillingComponent />
      )}


    </div>
  )
}

export default withStreamlitConnection(Login)
