import "bootstrap/dist/css/bootstrap.min.css";
import "./css/rocket.css";
import "./css/app.css";
// import "./css/@fortawesome/fontawesome-free/css/all.min.css"
import React from 'react';
import ReactDOM from 'react-dom';
import Login from "./Login";

ReactDOM.render(
  <React.StrictMode>
    <Login />
  </React.StrictMode>,
  document.getElementById('root')
);